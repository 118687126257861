import Header from "./components/Header";
import Footer from "./components/Footer";
import store from "./store";
import Content from "./components/Content";
import Web3 from "web3";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import TokenAdmin from "./components/TokenAdmin";
import React from "react";

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Header />

          <Routes>
            <Route path="/" element={<Content />} />
            <Route path="/token-admin" element={<TokenAdmin />} />
          </Routes>

          <Footer />
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
