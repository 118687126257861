import React, { useEffect, useState } from "react";
import Web3 from "web3";
import token from "./Token";
import { useSelector } from "react-redux";
import { connect } from "../store/actions/connected";
import { wallet } from "../store/actions/wallet";
import { chain } from "../store/actions/chain";
import { useDispatch } from "react-redux";
import Alert from "./small/Alert";
import { metamask } from "../store/actions/metamask";
import { waitForReceipt, getChain } from "./functions";
import Txprocessing from "./small/Txprocessing";
import Txsuccess from "./small/Txsuccess";
import { db, getWebsiteInfo } from "../firebaseConfig";
import Form from "react-bootstrap/Form";

function Content() {
  const dispatch = useDispatch();
  const connection = useSelector((state) => state.connect);
  const connectedAddress = useSelector((state) => state.wallet[0]);
  const isMetamask = useSelector((state) => state.metamask);

  async function connectWeb3() {
    try {
      if (window.ethereum) {
        const provider = window.ethereum;
        const web3 = new Web3(provider);
        const accounts = await provider.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];
        console.log(web3);
        const cha = web3.currentProvider.networkVersion;
        dispatch(chain(getChain(cha)));
        dispatch(wallet(account));
        dispatch(connect());
        provider.on("chainChanged", (_chainId) => window.location.reload());
      } else {
        dispatch(metamask());
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [walletAddress, setWalletAddress] = useState("");

  async function setWebsite() {
    const info = await getWebsiteInfo(db);
    setWalletAddress(info[0].walletAddress);
  }

  useEffect(() => {
    setWebsite();
  }, []);

  const [screen, setScreen] = useState("first");

  const initialToken = token[0];

  const [tokenTypeFront, setTokenTypeFront] = useState(initialToken);

  const [serviceFee, setServiceFee] = useState("");

  const [txError, setTxError] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  const [txHappening, setTxHappening] = useState(false);

  const [txMessage, setTxMessage] = useState("");

  const [txSuccess, setTxSuccess] = useState(false);

  const [txSuccessMessage, setTxSuccessMessage] = useState("");

  const [networkExplorer, setNetworkExplorer] = useState("");

  const [chosenNetwork, setChosenNetwork] = useState("");

  const [args, setArgs] = useState([]);

  const [networkCurrency, setNetworkCurrency] = useState("");

  //token bilgi state leri

  //token name
  const [tokenName, setTokenName] = useState("");

  //token symbol
  const [tokenSymbol, setTokenSymbol] = useState("");

  //token initial supply
  const [tokenInitialSupply, setTokenInitialSupply] = useState("");

  //token decimals
  const [tokenDecimals, setTokenDecimals] = useState("");

  //burnable buton kontrol
  const [isBurnable, setIsBurnable] = useState(false);
  const handleBurnableClick = () => {
    setIsBurnable(!isBurnable);
    console.log(isBurnable);
  };

  //mintable buton kontrol
  const [isMintable, setIsMintable] = useState(false);
  const handleMintableableClick = () => {
    setIsMintable(!isMintable);
  };

  //fee işlemleri
  //fee butonu kontrol
  const [feechecked, setFeechecked] = useState(false);

  const handlefeeClick = () => {
    setFeechecked(!feechecked);
  };

  //burn değeri
  const [burnvalue, setBurnvalue] = useState(5);

  //tax değeri
  const [taxvalue, setTaxvalue] = useState(5);

  //tax receiving address
  const [taxAddress, setTaxAddress] = useState("");
  //

  //redistribution işlemleri
  //redistribution kontrol
  const [redchecked, setRedchecked] = useState(false);

  const handleredClick = () => {
    setRedchecked(!redchecked);
  };

  //redistribution değeri
  const [redistributionvalue, setRedistributionvalue] = useState(5);

  useEffect(() => {
    tokenType();
  }, [isBurnable, isMintable, feechecked, redchecked]);

  useEffect(() => {
    setFrontNetwork();
  }, [screen, tokenTypeFront]);

  useEffect(() => {
    prepareArguments();
  }, [
    tokenInitialSupply,
    tokenName,
    tokenSymbol,
    tokenDecimals,
    tokenTypeFront,
    taxAddress,
  ]);

  function tokenType() {
    const ttype = [isBurnable, isMintable, feechecked, redchecked];

    token.forEach((a) => {
      if (
        a.tokenType[0] === ttype[0] &&
        a.tokenType[1] === ttype[1] &&
        a.tokenType[2] === ttype[2] &&
        a.tokenType[3] === ttype[3]
      ) {
        console.log(a);
        setTokenTypeFront(a);
      }
    });
  }

  function setFrontNetwork() {
    if (screen === "Bep-20") {
      setChosenNetwork("Binance Smart Chain");
      setServiceFee(tokenTypeFront.priceShare.bnbPrice);
      setNetworkCurrency("BNB");
      setNetworkExplorer("https://bscscan.com/");
    } else if (screen === "Erc-20") {
      setChosenNetwork("Ethereum Mainnet");
      setServiceFee(tokenTypeFront.priceShare.ethPrice);
      setNetworkCurrency("ETH");
      setNetworkExplorer("https://etherscan.com/");
    } else if (screen === "Frc-20") {
      setChosenNetwork("Fantom Mainnet");
      setServiceFee(tokenTypeFront.priceShare.ftmPrice);
      setNetworkCurrency("FTM");
      setNetworkExplorer("https://ftmscan.com/");
    } else if (screen === "Mrc-20") {
      setChosenNetwork("Polygon Mainnet");
      setServiceFee(tokenTypeFront.priceShare.maticPrice);
      setNetworkCurrency("MATIC");
      setNetworkExplorer("https://polygonscan.com/");
    }
  }

  function prepareArguments() {
    if (
      tokenTypeFront.tokenType[2] === false &&
      tokenTypeFront.tokenType[3] === false
    ) {
      setArgs([
        tokenInitialSupply,
        tokenName,
        tokenSymbol,
        tokenDecimals,
        walletAddress,
      ]);
    } else if (
      tokenTypeFront.tokenType[2] === true &&
      tokenTypeFront.tokenType[3] === false
    ) {
      setArgs([
        tokenInitialSupply,
        tokenName,
        tokenSymbol,
        tokenDecimals,
        burnvalue,
        taxvalue,
        taxAddress,
        walletAddress,
      ]);
    } else if (
      tokenTypeFront.tokenType[2] === false &&
      tokenTypeFront.tokenType[3] === true
    ) {
      setArgs([
        tokenInitialSupply,
        tokenName,
        tokenSymbol,
        tokenDecimals,
        redistributionvalue,
        walletAddress,
      ]);
    } else if (
      tokenTypeFront.tokenType[2] === true &&
      tokenTypeFront.tokenType[3] === true
    ) {
      setArgs([
        tokenInitialSupply,
        tokenName,
        tokenSymbol,
        tokenDecimals,
        burnvalue,
        taxvalue,
        redistributionvalue,
        taxAddress,
        walletAddress,
      ]);
    }
  }

  function correctNetwork() {
    const provider = window.ethereum;
    const web3 = new Web3(provider);
    const networkVer = web3.currentProvider.networkVersion;
    if (screen === "Bep-20" && networkVer == "56") {
      return true;
    } else if (screen === "Erc-20" && networkVer == "1") {
      return true;
    } else if (screen === "Frc-20" && networkVer == "250") {
      return true;
    } else if (screen === "Mrc-20" && networkVer == "137") {
      return true;
    } else {
      return false;
    }
  }

  async function deployToken() {
    try {
      if (!tokenInitialSupply || !tokenName || !tokenSymbol || !tokenDecimals) {
        setTxError(true);
        setErrorMsg("Please fill all the required information.");
        setTimeout(() => {
          setTxError(false);
        }, 5000);
        return;
      } else if (
        !Number(tokenInitialSupply) > 0 ||
        !Number(tokenDecimals) > 0
      ) {
        setTxError(true);
        setErrorMsg("Token initial supply and decimals should be numbers");
        setTimeout(() => {
          setTxError(false);
        }, 5000);
        return;
      } else if (feechecked) {
        if (
          taxAddress.length !== 42 &&
          taxAddress[0] !== "0" &&
          taxAddress[1] !== "x"
        ) {
          setTxError(true);
          setErrorMsg("Tax receiever address must be filled");
          setTimeout(() => {
            setTxError(false);
          }, 5000);
          return;
        }
      }
      console.log(Number(tokenInitialSupply), Number(tokenDecimals));
      const provider = window.ethereum;
      const web3 = new Web3(provider);

      async function wallet_watchAsset(tokenAddress, symbol, decimals) {
        const wasAdded = await provider.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress,
              symbol: symbol,
              decimals: decimals,
            },
          },
        });
      }

      const commission = web3.utils.toWei(String(serviceFee));

      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });
      console.log(tokenTypeFront, "token");
      const gascontract = new web3.eth.Contract(tokenTypeFront.tokenAbi);
      const options = {
        data: tokenTypeFront.tokenByte,
        arguments: args,
      };

      const estimatedGas = await gascontract.deploy(options).estimateGas({
        from: accounts[0],
        value: commission,
        gas: "10000000",
      });

      const contract = await new web3.eth.Contract(tokenTypeFront.tokenAbi)
        .deploy(options)
        .send(
          {
            from: accounts[0],
            value: commission,
            gas: estimatedGas,
            //gasPrice: '1000000000'
          },
          function (error, transactionHash) {
            if (error) {
              setTxError(true);
              setErrorMsg(error.message);
              setTimeout(() => {
                setTxError(false);
              }, 5000);
            } else if (transactionHash) {
              function txDone(receipt) {
                if (receipt.status) {
                  setTxHappening(false);
                  setTxSuccess(true);
                  setTimeout(() => {
                    setTxSuccess(false);
                  }, 2000);
                } else {
                  setTxHappening(false);
                  setTxError(true);
                  setErrorMsg("Error : Transaction reverted");
                  setTimeout(() => {
                    setTxError(false);
                  }, 5000);
                }
              }
              const txLink = networkExplorer + "tx/" + transactionHash;
              setTxHappening(true);
              setTxMessage(txLink);
              waitForReceipt(transactionHash, txDone);
            }
          }
        );

      console.log("deployed to ", contract.options.address);
      const txSucMes = networkExplorer + "contract/" + contract.options.address;
      setTxSuccessMessage(txSucMes);
      wallet_watchAsset(contract.options.address, tokenSymbol, tokenDecimals);
    } catch (error) {
      var msg;
      if (error.code == -32000) {
        msg = "This account doesn't have enough funds.";
      } else {
        msg = error.message;
      }
      console.log(error);
      setTxError(true);
      setErrorMsg(msg);
      setTimeout(() => {
        setTxError(false);
      }, 5000);
    }
  }

  return (
    <>
      <div className="page_wrapper">
        <section className="contact_page_section mb-140">
          <div className="container">
            <div className="contact_info_area">
              <div className="row">
                <div
                  className="col-lg-3 col-md-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => setScreen("Erc-20")}
                >
                  <div
                    className="contact_info_list left wow fadeInUp"
                    data-bgimg="assets/img/others/gaming-world-bg1.webp"
                    data-wow-delay="0.1s"
                    data-wow-duration="1.1s"
                  >
                    <div className="contact_info_thumb">
                      <img
                        width={115}
                        height={115}
                        src="assets/pics/ethereum.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => setScreen("Bep-20")}
                >
                  <div
                    className="contact_info_list center wow fadeInUp"
                    data-bgimg="assets/img/others/gaming-world-bg2.webp"
                    data-wow-delay="0.2s"
                    data-wow-duration="1.2s"
                  >
                    <div className="contact_info_thumb">
                      <img
                        width={115}
                        height={115}
                        src="assets/pics/binance-smart-chain.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => setScreen("Frc-20")}
                >
                  <div
                    className="contact_info_list center wow fadeInUp"
                    data-bgimg="assets/img/others/gaming-world-bg3.webp"
                    data-wow-delay="0.3s"
                    data-wow-duration="1.3s"
                  >
                    <div className="contact_info_thumb">
                      <img
                        width={115}
                        height={115}
                        src="assets/pics/fantom_logo.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => setScreen("Mrc-20")}
                >
                  <div
                    className="contact_info_list right wow fadeInUp"
                    data-bgimg="assets/img/others/gaming-world-bg3.webp"
                    data-wow-delay="0.3s"
                    data-wow-duration="1.4s"
                  >
                    <div className="contact_info_thumb">
                      <img
                        width={115}
                        height={115}
                        src="assets/pics/polygon_logo.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact_form_area">
              <div className="section_title text-center mb-60">
                <p>
                  {screen === "first" &&
                    "Choose A Network To Create Your Token"}
                  {screen !== "first" && (
                    <>
                      You are about to create your token on {chosenNetwork}{" "}
                      creation fee is {serviceFee + " " + networkCurrency}
                    </>
                  )}
                </p>
              </div>
              {screen !== "first" && (
                <div className="contact_form_inner">
                  <form id="contact-form" action="assets/mail.php">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form_input">
                          <input
                            type="text"
                            onChange={(e) => setTokenName(e.target.value)}
                            className="form-control"
                            autoComplete="off"
                            aria-describedby="Token Name"
                            placeholder="Token Name e.g. My Token"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_input">
                          <input
                            type="text"
                            onChange={(e) => setTokenSymbol(e.target.value)}
                            className="form-control"
                            autoComplete="off"
                            aria-describedby="Token Symbol"
                            placeholder="Token Symbol e.g. MT"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_input">
                          <input
                            type="text"
                            onChange={(e) =>
                              setTokenInitialSupply(e.target.value)
                            }
                            className="form-control"
                            autoComplete="off"
                            aria-describedby="Token initial supply"
                            placeholder="initial supply e.g. 18000000"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_input">
                          <input
                            type="text"
                            onChange={(e) => setTokenDecimals(e.target.value)}
                            className="form-control"
                            autoComplete="off"
                            aria-describedby="Token decimals"
                            placeholder="Decimals e.g. 18"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 text-center p-3">
                        {" "}
                        <div
                          style={{
                            border: "2px solid #281E4D",
                            borderRadius: "10px",
                          }}
                          className="p-2 mt-2"
                        >
                          <h3>Burnable</h3>
                          <Form.Check
                            size="lg"
                            type="switch"
                            onChange={handleBurnableClick}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 text-center mt-2 p-3">
                        {" "}
                        <div
                          style={{
                            border: "2px solid #281E4D",
                            borderRadius: "10px",
                          }}
                          className="p-2"
                        >
                          <h3>Mintable</h3>
                          <Form.Check
                            size="lg"
                            type="switch"
                            onChange={handleMintableableClick}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 text-center mt-2 p-3">
                        {" "}
                        <div
                          style={{
                            border: "2px solid #281E4D",
                            borderRadius: "10px",
                          }}
                          className="p-2"
                        >
                          <h3>Fee / Tax</h3>
                          <Form.Check
                            size="lg"
                            type="switch"
                            onChange={handlefeeClick}
                          />
                          {feechecked && (
                            <>
                              <div>
                                <p className="switch mr-1">Burn Fee</p>
                                <input
                                  type="range"
                                  className="slider-width100"
                                  defaultValue={5}
                                  min={0}
                                  max={10}
                                  onChange={(e) => {
                                    setBurnvalue(e.target.value);
                                  }}
                                  required
                                />
                                {"%" + burnvalue}
                              </div>
                              <div>
                                <p className="switch mr-1">Tax Fee</p>
                                <input
                                  type="range"
                                  className="slider-width100"
                                  defaultValue={5}
                                  min={0}
                                  max={10}
                                  onChange={(e) => {
                                    setTaxvalue(e.target.value);
                                  }}
                                  required
                                />
                                <span className="white-text ml-2">
                                  {"%" + taxvalue}
                                </span>
                              </div>
                              <div className="form_input">
                                <p className="switch mr-1">
                                  Tax Receiving Address
                                </p>

                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    setTaxAddress(e.target.value)
                                  }
                                  autoComplete="off"
                                  aria-describedby="Token Name"
                                  placeholder="e.g. 0x1111111111111111111111111111111111111111"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 text-center mt-2 p-3">
                        <div
                          style={{
                            border: "2px solid #281E4D",
                            borderRadius: "10px",
                          }}
                          className="p-2"
                        >
                          <h3>Holder Redistribution</h3>
                          <Form.Check
                            size="lg"
                            type="switch"
                            onChange={handleredClick}
                          />
                          {redchecked && (
                            <>
                              <p className="switch">Redistribution Fee</p>
                              <input
                                type="range"
                                className="slider-width100"
                                defaultValue={5}
                                min={0}
                                max={10}
                                onChange={(e) => {
                                  setRedistributionvalue(e.target.value);
                                }}
                                required
                              />
                              <span className="white-text ml-2">
                                {"%" + redistributionvalue}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form_input_btn text-center">
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={!connection ? connectWeb3 : deployToken}
                      >
                        {connection ? "Deploy" : "Connect"}
                      </button>
                    </div>
                    <p className="form-message" />
                  </form>
                </div>
              )}
            </div>
          </div>
        </section>

        {isMetamask === false ? (
          <Alert
            alertType={"alert-danger"}
            alertContent={`You need to install Metamask to use this app.`}
          />
        ) : null}
        {txHappening && <Txprocessing alertContent={txMessage} />}
        {txSuccess && <Txsuccess alertContent={txSuccessMessage} />}
        {txError === true ? (
          <Alert alertType={"alert-danger"} alertContent={errorMsg} />
        ) : null}
      </div>
    </>
  );
}

export default Content;
