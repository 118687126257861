import React from "react";

function Footer() {
  return (
    <>
      <footer className="footer_widgets">
        <div className="main_footer">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="main_footer_inner d-flex"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="footer_bottom_inner d-flex justify-content-center">
                  <div className="copyright_right">
                    <p>
                      © 2023 Powered by{" "}
                      <a href="https://vikingtoken.io" target="_blank">
                        {" "}
                        Viking Token
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
