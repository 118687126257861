// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBFFxE-2qStV5oBIQ1WjdsPvxacL85Q1Bw",
  authDomain: "viking-c7952.firebaseapp.com",
  projectId: "viking-c7952",
  storageBucket: "viking-c7952.appspot.com",
  messagingSenderId: "558728835451",
  appId: "1:558728835451:web:8bb2487a937bb230e18dde",
  measurementId: "G-JSKV9F2WXB",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

// Get a list of cities from your database
export async function getCities(db) {
  const citiesCol = collection(db, "changeInfo");
  const citySnapshot = await getDocs(citiesCol);
  const cityList = citySnapshot.docs.map((doc) => doc.data());
  return cityList;
}

export async function getWebsiteInfo(db) {
  const citiesCol = collection(db, "websiteInfo");
  const citySnapshot = await getDocs(citiesCol);
  const cityList = citySnapshot.docs.map((doc) => doc.data());
  return cityList;
}

export async function getSocialLinks(db) {
  const citiesCol = collection(db, "socialLinks");
  const citySnapshot = await getDocs(citiesCol);
  const cityList = citySnapshot.docs.map((doc) => doc.data());
  return cityList;
}
