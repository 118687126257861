import React from "react";

function Loading() {
  return (
    <div className="container-fluid">
      <div
        style={{
          backgroundColor: "#0A002C",
          width: "100%",
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src="assets/pics/loading.webp" />
      </div>
    </div>
  );
}

export default Loading;
