import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { connect } from "../store/actions/connected";
import { chain } from "../store/actions/chain";
import { wallet } from "../store/actions/wallet";
import { metamask } from "../store/actions/metamask";
import { useSelector } from "react-redux";
import { getChain } from "./functions";
import Web3 from "web3";
import { db, getWebsiteInfo, getSocialLinks } from "../firebaseConfig";

function Header() {
  const dispatch = useDispatch();
  const connection = useSelector((state) => state.connect);
  const connectedAddress = useSelector((state) => state.wallet[0]);
  const chainex = useSelector((state) => state.chain);

  const [social, setSocial] = useState("");

  async function setWebsite() {
    const info = await getWebsiteInfo(db);
    document.title = info[0].title;
    console.log(info[0]);
    const soc = await getSocialLinks(db);
    setSocial(soc[0].telegram);
  }

  useEffect(() => {
    setWebsite();
  }, []);

  async function connectWeb3() {
    try {
      if (window.ethereum) {
        const provider = window.ethereum;
        const web3 = new Web3(provider);
        const accounts = await provider.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];
        const cha = web3.currentProvider.networkVersion;
        const chainf = getChain(cha);
        dispatch(chain(chainf));
        dispatch(wallet(account));
        dispatch(connect());
        provider.on("chainChanged", (_chainId) => window.location.reload());
      } else {
        dispatch(metamask());
      }
    } catch (error) {
      console.log(error);
    }
  }

  function openTelegram() {
    window.open(social);
  }

  return (
    <>
      <header
        className="header_section header_transparent sticky-header"
        style={{ position: "sticky" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="main_header d-flex justify-content-between align-items-center">
                <div className="header_logo">
                  <a className="sticky_none" href="/">
                    <img
                      aria-label="logo"
                      width={80}
                      height={79}
                      src="assets/pics/tokenlogo.png"
                      alt=""
                    />
                  </a>
                </div>
                {/*main menu start*/}
                <div className="main_menu d-none d-lg-block">
                  <nav>
                    <ul className="d-flex">
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/token-admin">Admin</a>
                      </li>
                    </ul>
                  </nav>
                </div>
                {/*main menu end*/}
                <div className="header_right_sidebar d-flex align-items-center">
                  <a className="btn btn-link" onClick={connectWeb3}>
                    {!connection ? "Connect" : "Disconnect"}
                    <img
                      width={15}
                      height={15}
                      src="assets/img/icon/arrrow-icon2.webp"
                      alt=""
                    />
                  </a>
                  <div className="canvas_open">
                    <button
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasMenu"
                    >
                      <i className="icofont-navigation-menu" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        className="offcanvas offcanvas-start"
        tabIndex={-1}
        id="offcanvasMenu"
      >
        <div className="offcanvas-header justify-content-end">
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          <ul className="offcanvas_main_menu">
            <li className="menu-item-has-children active">
              <a href="/">Home</a>
            </li>
            <li className="menu-item-has-children active">
              <a href="/token-admin">Admin</a>
            </li>
          </ul>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default Header;
